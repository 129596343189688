import {useEffect, useState} from "react";
import { FastLaneInstance, type MediaType, Takeover as TakeoverType } from '../types';
import {useFastLane} from "../store";
import { useUniversalTakeover } from './sequence-and-takeover-controller.hooks';

export default function SequenceAndTakeoverController({instance}: {instance: FastLaneInstance}) {
    const takeover = instance?.takeover;
    const sequence = instance.sequence;
    const enableTakeover = useFastLane(state => state.enableTakeover);
    const setTakeover = useFastLane(state => state.setTakeover);
    const playSequence = useFastLane(state => state.playSequence);
    const setSequence = useFastLane(state => state.setSequence);
    const stopSequence = useFastLane(state => state.stopSequence);

    useUniversalTakeover();

    function handleTakeover() {
        let id = null;

        enableTakeover(takeover).then(() => {
            id = setInterval(() => {
                enableTakeover(takeover).then(() => {
                    // setPlayIndex(playIndex + 1);
                })
            }, takeover?.scheduling?.interval);
        });

        return () => clearInterval(id);
    }

    function handleSequence() {
        stopSequence();
        setTakeover({...takeover, enabled: false});

        const id = setTimeout(() => {
            setSequence({
                ...sequence,
                skipTakeover: !takeover?.enabled,
            });

            playSequence().then(() => {});
        }, 1_000)

        return () => {
            stopSequence();
            clearTimeout(id);
        }
    }

    useEffect(() => {
        if ((!takeover?.enabled && !sequence?.enabled) || instance?.isEditor) {
            return;
        }

        if (sequence?.enabled) {
            return handleSequence();
        }

        return handleTakeover();
    }, [instance?.takeover, instance?.sequence]);

    return null;
}