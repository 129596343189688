import Background from "./components/background";
import Entries from "./components/entries";
import Takeover from "./components/takeover";
import PixelMap from "./components/pixlemap";
import {MotionConfig} from "framer-motion";
import * as AppEventsService from '../../../../services/app-events.service';
import TemplateEventsEnum from "../../../../enums/TemplateEvents.enum";
import {useFastLane} from "./store";
import {DisplayType, FastLaneInstance} from "./types";
import {useEffect} from "react";
import {DEFAULT_EASE} from "../../../rise/constants/transition";
import EditorLayer from "./components/editor-layer";
import {FastLaneContextProvider} from "./store/fast-lane-context";
import LiveEditorController from "./components/live-editor-controller";
import './fonts.scss';
import SequenceAndTakeoverController from "./components/sequence-and-takeover-controller";
import useTickerTakeover from "../../../../services/takeover-service/takeover-ticker.hooks";

interface FastLaneProps {
    instance: FastLaneInstance;
    display: DisplayType;
    preventInitializing?: boolean;
}

export default function FastLane({instance, display, preventInitializing}: FastLaneProps) {
    const initializeInstance = useFastLane(f => f.initializeInstance);
    const playHeadline = useFastLane(f => f.playContentRotation);

    useEffect(() => {
        if (preventInitializing) {
            return;
        }
        AppEventsService.sendEvent(TemplateEventsEnum.Init);
        initializeInstance(instance, display);
        AppEventsService.sendEvent(TemplateEventsEnum.InitEnd);
        playHeadline();
    }, []);

    const pixelMap = instance.pixelMap.ticker;

    const {
        component: takeoverComponent,
        primaryColor,
    } = useTickerTakeover({
        canvas_width: pixelMap.contentWidth,
        canvas_height: pixelMap.wrapperHeight,
        width: pixelMap.wrapperWidth,
        height: pixelMap.contentHeight,
    });

    return (
        <FastLaneContextProvider displayType={display} instance={instance}>

            <LiveEditorController/>
            <SequenceAndTakeoverController instance={instance}/>

            <MotionConfig transition={DEFAULT_EASE}>
                <div
                    style={{
                        '--primary-color': display === 'ticker' ? primaryColor : undefined,
                    }}
                >
                    <PixelMap>
                        {(lane) => (
                            <>
                                <Background
                                    laneIndex={lane.laneIndex}
                                    totalLanes={lane.totalLanes}
                                />
                                <Entries/>
                                <Takeover/>
                                <EditorLayer/>
                            </>
                        )}
                    </PixelMap>

                    {display === 'ticker' && takeoverComponent ? takeoverComponent : null}
                </div>
            </MotionConfig>
        </FastLaneContextProvider>
    );
}
