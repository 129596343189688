import React, { useCallback, useEffect, useState } from 'react';
import * as Hyperlive from '../../hyperlive/utils.js';
import _ from 'lodash';
import i18n from '../../i18n';
import './RiseStoryTelling.scss';
import LottiePlayer, { LottieModes } from './components/LottiePlayer/LottiePlayer.js';
import RiseVerticalFS from './scenes/RiseVerticalFS/RiseVerticalFS.js';
import RiseHorizontalFS from './scenes/RiseHorizontalFS/RiseHorizontalFS.js';
import RiseVerticalGrid from './scenes/RiseVerticalGrid/RiseVerticalGrid.js';
import RiseHorizontalGrid from './scenes/RiseHorizontalGrid/RiseHorizontalGrid.js';
import RiseHorizontalGridSecondary from './scenes/RiseHorizontalGridSecondary/RiseHorizontalGridSecondary.js';


// LOTTIE MODE (SEE OPTIONS IN RiseStorytelling.md)
const LOTTIE_MODE = LottieModes.Chapter;

// LANGUAGES
const storeLanguages = Hyperlive.getStoreLanguages();
// TEST LANG
// const storeLanguages = ['EN', 'KO', 'CN']

// SPORTPULSE PREVIEW OVERRIDES
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

let PREVIEW_CHAPTER = urlParams.get('chapter');
if (PREVIEW_CHAPTER === "null") { PREVIEW_CHAPTER = null }
if (PREVIEW_CHAPTER) { console.warn('FORCE CHAPTER', PREVIEW_CHAPTER); }

const PREVIEW_LANGUAGE = urlParams.get('lang');
if (PREVIEW_LANGUAGE) { console.warn('FORCE LANGUAGE', PREVIEW_LANGUAGE); }

// TEMPLATE CONSTANTS
const ORIENTATION = {
  Horizontal: 'Horizontal',
  Vertical: 'Vertical',
}
const LAYOUT = {
  Gridded: 'gridded',
  Fullscreen: 'fullscreen',
}
const LOTTIE_GRIDS = {
  Rise_Vertical_FS_Duo: require('./grids/rise_v_fs_duo.json'),
  Rise_Vertical_FS_Solo: require('./grids/rise_v_fs_solo.json'),
  Rise_Vertical_Grid_Primary: require('./grids/rise_v_grid_1.json'),
  Rise_Horizontal_FS_Solo: require('./grids/rise_h_fs_solo.json'),
  Rise_Horizontal_FS_Duo: require('./grids/rise_h_fs_duo.json'),
  Rise_Horizontal_Grid_Primary: require('./grids/rise_h_grid_1.json'),
  Rise_Horizontal_Grid_Secondary: require('./grids/rise_h_grid_2.json'),
};
const COMPONENTS = {
  RiseVerticalFS: RiseVerticalFS,
  RiseHorizontalFS: RiseHorizontalFS,
  RiseVerticalGrid: RiseVerticalGrid,
  RiseVerticalGridSecondary: RiseVerticalGrid,
  RiseHorizontalGrid: RiseHorizontalGrid,
  RiseHorizontalGridSecondary: RiseHorizontalGridSecondary,
}

const SCREEN = {
  width: window.innerWidth,
  height: window.innerHeight,
  orientation: (window.innerWidth / window.innerHeight) > 1 ? ORIENTATION.Horizontal : ORIENTATION.Vertical
}

let gridLayoutIndex = 0;
let fsLayoutIndex = 0;

const selectLayout = (media, chapter_type) => {

  const templateLayout = (media.length > 2 || chapter_type === 'grid') ? LAYOUT.Gridded : LAYOUT.Fullscreen;
  let grid = LOTTIE_GRIDS[`Rise_${SCREEN.orientation}_FS_Solo`];
  let component = COMPONENTS[`Rise${SCREEN.orientation}FS`];

  switch (templateLayout) {
    case LAYOUT.Gridded:
      grid = (gridLayoutIndex % 2 === 0 || SCREEN.orientation === ORIENTATION.Vertical) ? LOTTIE_GRIDS[`Rise_${SCREEN.orientation}_Grid_Primary`] : LOTTIE_GRIDS[`Rise_${SCREEN.orientation}_Grid_Secondary`];
      component = (gridLayoutIndex % 2 === 0 || SCREEN.orientation === ORIENTATION.Vertical) ? COMPONENTS[`Rise${SCREEN.orientation}Grid`] : COMPONENTS[`Rise${SCREEN.orientation}GridSecondary`];
      gridLayoutIndex++
      break;
    case LAYOUT.Fullscreen:
      grid = LOTTIE_GRIDS[`Rise_${SCREEN.orientation}_FS_Solo`];
      component = COMPONENTS[`Rise${SCREEN.orientation}FS`];
      fsLayoutIndex++
    default:
      break;
  }

  return { grid, component }
}


const RiseStorytelling = ({ json_data, ignoreUrlParams }) => {

  const [frame, setFrame] = useState(0);
  const [index, setIndex] = useState(!ignoreUrlParams && PREVIEW_CHAPTER ? PREVIEW_CHAPTER : 0);
  const [SEQUENCE, setSEQUENCE] = useState([]);
  const [lang, setLang] = useState(storeLanguages[0]);

  // CREATE RUN OF SHOW
  useEffect(() => {
    console.log(`${SCREEN.width}x${SCREEN.height}`);
    console.warn('MODE', LOTTIE_MODE);
    console.log('RISE_STORY_TELLING » JSON', json_data);
    let animation_sequence = [];
    let precache_media = [];
    const chapters = _.get(json_data, 'chapters');
    chapters.map((chapter, index) => {
      const assets = _.get(chapter, `assets`, []);
      const media = assets.filter(item => item !== null);
      const headline = _.get(chapter, `headline`);
      const body = _.get(chapter, `body`);
      const chapter_type = _.get(chapter, `chapter_type`);

      console.log('CT', chapter_type)
      // // TEST LANG
      // if (headline) {
      //   headline.EN = 'ENGLISH HEADING'
      //   headline.KO = 'KOREAN HEADING'
      //   headline.CN = 'CHINESE HEADING'
      // }
      // if (body) {
      //   body.EN = 'ENGLISH BODY'
      //   body.KO = 'KOREAN BODY'
      //   body.CN = 'CHINESE BODY'
      // }

      // console.log(media, headline, body)
      const { component, grid } = selectLayout(media, chapter_type);
      animation_sequence.push({
        id: `chapter${index}`,
        grid,
        component,
        media,
        headline,
        body,
      });
      precache_media = precache_media.concat(media);
    })
    setSEQUENCE(animation_sequence);

  }, [])

  // LOTTIE EVENT HANDLER
  const onEvent = (event) => {
    let nextIndex = index + 1;
    switch (event) {
      case "complete":
      case "loop":
        console.warn('COMPLETE CHAPTER')
        setFrame(0);
        setIndex(nextIndex);
        break;
      case "load":
        // Do nothing on purpose
        break;
      case "frame":
        // Do nothing on purpose
        break;
    }
  }

  const sequence_length = _.get(SEQUENCE, 'length')
  const chapter = !ignoreUrlParams && PREVIEW_CHAPTER ? PREVIEW_CHAPTER : index % sequence_length;
  const active_sequence = _.get(SEQUENCE, `[${chapter}]`)
  const grid = _.get(active_sequence, `grid`)
  const media = _.get(active_sequence, `media`)
  const key = _.get(active_sequence, `id${!ignoreUrlParams && PREVIEW_CHAPTER ? Math.floor(Math.random() * 100) : ''}`)
  const langLength = _.get(storeLanguages, 'length');
  const langIndex = (index - chapter) / sequence_length % langLength;
  const langCode = !ignoreUrlParams && PREVIEW_LANGUAGE ? PREVIEW_LANGUAGE : _.get(storeLanguages, `[${langIndex}]`);
  const body = _.get(active_sequence, `body.${langCode}`)
  const headline = _.get(active_sequence, `headline.${langCode}`)
  const Component = _.get(active_sequence, `component`)

  return (
    <div className={`RiseStoryTelling orientation-${SCREEN.orientation}`} key={`RST-${index}`}>

      {active_sequence &&
        <React.Fragment>
          {
            LOTTIE_MODE === LottieModes.Global
              ?
              <React.Fragment>
                <div className='RiseStoryTelling-lottie'>
                  <LottiePlayer grid={grid} onEvent={onEvent} mode={LOTTIE_MODE} />
                </div>
                <div className='RiseStoryTelling-media'>
                  <Component
                    headline={headline}
                    body={body}
                    media={media}
                    key={`layout-${key}`}
                  />
                </div>
              </React.Fragment>
              :
              <div className='RiseStoryTelling-media'>
                <Component
                  headline={headline}
                  body={body}
                  media={media}
                  key={`layout-${key}`}
                  grid={grid}
                  onEvent={onEvent}
                />
              </div>
          }
        </React.Fragment>
      }
    </div>
  );
};

export default RiseStorytelling;



