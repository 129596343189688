import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';

import { version } from '../../package.json';
import { SENTRY_DSN } from '../config';


export default function initSentry() {
    if (!SENTRY_DSN) {
        return;
    }

    Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [
            new Integrations.BrowserTracing(),
            new CaptureConsole({
                levels: ['error'],
            }),
        ],
        release: `rise-displays@${version}`,
        environment: window.location.host,
        tracesSampleRate: 0.1,
    });
}
