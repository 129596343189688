import React, { useEffect, useMemo, useRef, useState } from 'react';
import PixelMap from "../PixelMap";
import WrappableElement from "../WrappableElement";
import './reset.scss';
import {extractData} from "../../utilities";
import {pixelMap} from "../../api";
import _, {get, chain} from "lodash";
import * as AppEventsService from '../../services/app-events.service'
import PixelMapEventsEnum from '../../enums/PixelMapEvents.enum';
import Overlay from "../../touchpoints/core/animations/overlay/Overlay";
import PixelMapChannelSlot from "../PixelMapChannelSlot";
import PixelMapDisplaySlot from '../PixelMapDisplaySlot';
import {
  ANALYTICS_BASE_URL,
  DISABLE_SEND_HEARTBEATS_QUERY_PARAM_KEY,
  IS_ANALYTICS_ENABLED,
  SEND_HEARTBEAT_INTERVAL_SECONDS,
  HEARTBEATS_DELAY,
} from '../../config';
import { PixelMapVitals } from 'vitals';

const TICKERS_DEMO = {
  screen: {
    // width: 1400,
  },
  sections: [
    {
      wrap: [
        {
          type: 'iframe',
          url: '/touchpoint/?channel=ll_ticker_men_1111',
          width: 1000,
          height: 250,
        },
        {
          type: 'iframe',
          url: '/touchpoint/?channel=ll_ticker_men_1111',
          width: 1000,
          height: 250,
        }
      ]
    },
    {
      wrap: [
        {
          type: 'iframe',
          url: '/touchpoint/?channel=ll_ticker_men_1111',
          width: 1000,
          height: 250,
        },
        {
          type: 'iframe',
          url: '/touchpoint/?channel=ll_ticker_women_1111',
          width: 1000,
          height: 250,
        }
      ]
    },
    {
      transform: {
        scaleY: .3,
      },
      wrap: [
        {
          type: 'iframe',
          url: '/touchpoint/?channel=ll_ticker_men_1111',
          width: 1000,
          height: 250,
        },
        {
          type: 'iframe',
          url: '/touchpoint/?channel=ll_ticker_women_1111',
          width: 1000,
          height: 250,
        }
      ]
    },
    {
      transform: {
        rotate: '180deg',
      },
      wrap: [
        {
          type: 'iframe',
          url: '/touchpoint/?channel=ll_ticker_men_1111',
          width: 1000,
          height: 250,
        },
        {
          type: 'iframe',
          url: '/touchpoint/?channel=ll_ticker_women_1111',
          width: 1000,
          height: 250,
        }
      ]
    },
  ]
};

const COVER_STORY_DEMO = {
  screen: {},
  sections: [
    {
      transform: {
        scaleY: 0.5,
        y: '-25%',
      },
      wrap: [
        {
          type: 'iframe',
          url: '/touchpoint/?channel=cover_story_men_1111&demo=pixel-map',
          width: 1620,
          height: 1080,
        }
      ]
    },
    {
      transform: {
        scaleY: 0.5,
        y: '-75%',
      },
      wrap: [
        {
          type: 'iframe',
          url: '/touchpoint/?channel=cover_story_women_1111&demo=pixel-map',
          width: 1620,
          height: 1080,
        }
      ]
    }
  ]
};

const SERVICE_HUB = {
  screen: {
    width: 1920,
    height: 1080,
  },
  transform: {
    // scaleY: '50%',
    transformOrigin: '0 0'
  },
  sections: [
    {
      wrap: [
        {
          type: 'iframe',
          url: '/touchpoint/?channel=tickers_mall_entrance_kr&origin=pixel-map&width=768&height=48',
          width: 768,
          height: 48,
        }
      ]
    },
    {
      wrap: [
        {
          type: 'spacer',
          width: 1008,
          height: 48,
        },
        {
          type: 'iframe',
          url: '/touchpoint/?channel=tickers_mall_entrance_kr&origin=pixel-map&width=3696&height=48',
          width: 3696,
          height: 48,
        }
      ],
    },
    {
      wrap: [
        {
          type: 'spacer',
          width: 720,
          height: 48,
        },
        {
          type: 'iframe',
          url: '/touchpoint/?channel=tickers_mall_entrance_kr&origin=pixel-map&width=3504&height=48',
          width: 3504,
          height: 48,
        }
      ],
    },
  ]
};

const PIXEL_MAP_WRAP_TYPE = {
    IFRAME: 'iframe',
    SPACER: 'spacer',
    DISPLAY: 'display',
};

function query(item, content) {
  let q = '';

  if (item?.query && item.query !== '') {
    q += `&${item?.query}`;
  }

  if (content?.query && content.query !== '') {
    q += `&${content?.query}`;
  }

  return q;
}

const DemoPixelMap = () => {
  const params = new URLSearchParams(window.location.search);
  const [content, setContent] = useState(null);
  const [isLodef, setLodef] = useState(false);
  const lastUpdated = useRef(null);
  const [isConfigured, updateConfigurationState] = useState(false);
  const intervalRef = useRef(null);

  console.log(content);

  useEffect(() => {
    AppEventsService.sendEvent(PixelMapEventsEnum.Init);

    const slug = params.get('pixelmap');
    const lodef = params?.get('lodef') === 'true';
    setLodef(lodef);

    const staticContent = {
      tickers_extreme: () => ([...TICKERS_DEMO, ...TICKERS_DEMO]),
      tickers: () => TICKERS_DEMO,
      cover_story: () => COVER_STORY_DEMO,
      'service-hub': () => SERVICE_HUB,
      data: () => extractData(),
    }[slug] ?? null;

    if (staticContent && typeof staticContent === 'function') {
      return setContent(staticContent());
    }

    pixelMap(slug).then(async (pixelMapPayload) => {
      AppEventsService.sendEvent(PixelMapEventsEnum.DataLoaded);

      const {
        json_data,
        updated_at,
      } = pixelMapPayload;

      setContent(json_data);
      lastUpdated.current = updated_at;
      checkForUpdates(slug);

      const shouldInitializeHeartbeatsSending = !lodef && !isPreviewMode && !isHeartbeatsSendingDisabled;

      if (IS_ANALYTICS_ENABLED && shouldInitializeHeartbeatsSending) {
        startSendHeartbeats(pixelMapPayload);
      }
    });

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const startSendHeartbeats = (pixelMapPayload) => {
    const pixelMapSlug = params.get('pixelmap');

    const vitals = new PixelMapVitals({
        baseUrl: ANALYTICS_BASE_URL,
        slug: pixelMapSlug,
        label: pixelMapSlug,
        storeId: _.get(pixelMapPayload, 'store_id'),
        delay: HEARTBEATS_DELAY,
    });

    intervalRef.current = setInterval( () => vitals.sendHeartbeat(), SEND_HEARTBEAT_INTERVAL_SECONDS);
  }

  const isPreviewMode = useMemo(() => {
    return params.get('mode') === 'preview';
  }, [params]);

  const isHeartbeatsSendingDisabled = useMemo(() => {
    return params.get(DISABLE_SEND_HEARTBEATS_QUERY_PARAM_KEY) === 'true';
  }, [params]);

  const isWrapValid = (wrapItem) => {
    if (!wrapItem) {
      return false;
    }

    const isValidSimpleWrap = [
      PIXEL_MAP_WRAP_TYPE.SPACER,
      PIXEL_MAP_WRAP_TYPE.DISPLAY,
    ].includes(wrapItem.type);
    const isValidIframeWrap = wrapItem.type === PIXEL_MAP_WRAP_TYPE.IFRAME && wrapItem.channel;

    return isValidSimpleWrap || isValidIframeWrap;
  }

  const checkConfigurationState = () => {
    const sections = get(content, 'sections', []);

    if (!sections || !sections.length) {
      return false;
    }

    const mappedSectionsWrap = chain(sections)
      .map('wrap')
      .flatten()
      .value();

    return mappedSectionsWrap.some(isWrapValid);
  }

  useEffect(() => {
      updateConfigurationState(checkConfigurationState());
  }, [content]);

  /**
   * Will check every minute for changes.
   *
   * @param slug
   */
  const checkForUpdates = (slug) => {
    const i = setInterval(() => {
        pixelMap((slug)).then(({updated_at}) => {
          AppEventsService.sendEvent(PixelMapEventsEnum.DataLoaded);

          if (updated_at !== lastUpdated.current) {
            clearInterval(i);
            location.reload();
          }
        }).catch((err) => {
          AppEventsService.sendEvent(PixelMapEventsEnum.DataError);
          console.warn('could not load pixelmap', err)
        })

    }, 60_000);
  };

  const groupHeight = (group) => {
    return group.reduce((acc, item) => {
      return Math.max(acc, item?.height ?? 200);
    }, 0)
  }

  const groupWidth = (group) => {
    return group.reduce((acc, item) => {
      return acc + item?.width ?? window.innerWidth;
    }, 0)
  }

  const wrapsInternally = (group) => {
    const isInternalWrapping = (query) => query?.indexOf('wrapping-style=internal') >= 0

    return group.filter((item) => {
      return isInternalWrapping(item.query);
    }).length === 1;
  }

  const getTotalCount = () => {
    return content?.sections.reduce((acc, curr) => {
      return acc + curr?.wrap?.filter(a => a.type === 'iframe')?.length ?? 0;
    }, 0);
  }

  const groupTransform = (group) => {
    return {
      ...group?.transform ?? {},
    };
  }

  const generateChannelUrl = (item, urlParams) => {
	  const pixelMapSlug = params.get('pixelmap');

	  const {
		  baseQuery,
		  index,
		  total,
	  } = urlParams;

	  const searchParams = new URLSearchParams({
		  channel: item.channel,
		  origin: 'pixel-map',
		  label: `pixel-map-${pixelMapSlug}`,
		  'pixelmap-slug': pixelMapSlug,
		  width: item.width,
		  'screen-width': item?.screenwidth ?? content?.screen?.width,
		  height: item.height,
		  index,
		  total
	  });

	  if (isPreviewMode || isHeartbeatsSendingDisabled) {
		  searchParams.set(DISABLE_SEND_HEARTBEATS_QUERY_PARAM_KEY, 'true');
	  }

    const prefix = window.location.origin + window.location.pathname;

	  return `${prefix}?${searchParams.toString()}${baseQuery}`;
  }

  const renderItem = (item, t, i) => {
    const props = {
      key: `wrap_${i}_${t}`,
      width: item.width,
      height: item.height,
    }

    if (item.type === 'spacer') {
      const {key, ...style} = props;
      return (
        <div
          key={key}
          style={{
            ...style
          }}
        />
      );
    }

    if (!item?.url && !item?.channel && item?.type !== PIXEL_MAP_WRAP_TYPE.DISPLAY) {
      return null;
    }

    if (isLodef || !item.channel) {
      const { width, height, ...otherProps } = props;
      return item.type === PIXEL_MAP_WRAP_TYPE.DISPLAY ? (
        <PixelMapDisplaySlot
          key={props.key}
          width={width}
          height={height}
          position={item.position}
          areas={item.areas}
        />
      ) : (
        <PixelMapChannelSlot
          {...otherProps}
          width={width}
          height={height}
          channelIndex={i}
        />
      );
    }

    const initialIndex = Number(content?.screen?.initial_index ?? 0);
    const index = t + initialIndex;
    const total = getTotalCount() + initialIndex;

    const url = item.hasOwnProperty('url')
      ? item.url
      : generateChannelUrl(item, { baseQuery: query(item, content), index, total });

    return (
      <iframe
        {...props}
        frameBorder="0"
        src={url}
      />
    );
  }

  if (!content) {
    return (
      <div>Loading...</div>
    );
  }

  if (!isConfigured) {
      return <Overlay />;
  }

  return (
    <div
      style={{
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        top: 0,
        left: 0,
        background: isLodef ? 'rgb(217, 217, 217)' : undefined,
      }}
    >
      <PixelMap
        config={content?.screen}
        timingFunction={content?.timing_function}
        transform={content?.transform}
        testMode={isLodef}
      >
        {
          content && content?.sections && content.sections?.length > 0 && content.sections.filter(({wrap}) => wrap).filter(({wrap}) => groupWidth(wrap) > 0).map((group, i) => (
            <WrappableElement
              testOff={!isLodef}
              key={`wrap_${i}`}
              transform={groupTransform(group)}
              width={groupWidth(group.wrap)}
              height={groupHeight(group.wrap)}
              wrapsInternally={wrapsInternally(group.wrap)}
              sectionWidth={group.section_width}
            >
              {
                group.wrap.map((item, t) => renderItem(item, t, i))
              }
            </WrappableElement>
          ))
        }
      </PixelMap>
    </div>
  );
};

export default DemoPixelMap;
